import { useMutation } from "@tanstack/react-query";
import { update as updateActivity } from "api/requests/activity/update";
import { update as updateTravelActivity } from "api/requests/travelActivity/update";
import { removeEmptyStringAttributes } from "utils/removeEmptyStringAttributes";
import { travel_activities } from "types/data/TravelActivities";
import { toast } from "react-toastify";

export const useUpdateTravelActivity = () => {
  return useMutation({
    mutationKey: ["update-travel-activity"],
    mutationFn: (item: any) =>
      updateActivity(
        removeEmptyStringAttributes({
          id: item.activity_id,
          startsat: item.startsat,
          endsat: item.endsat,
          title: item.activityTitle,
          activity_type: item.activityType,
          budget: item.activityBudget,
        })
      ),
    onSuccess: (data, variables) => {
      updateTravelActivity(
        removeEmptyStringAttributes({
          id: variables.id,
          travel_id: variables.travel_id,
          activity_id: variables.activity_id,
          activitydate: variables.activitydate,
          ordenation: variables.activityOrder,
          isvisible: variables.isvisible,
          description: variables.activityDescription,
        } as travel_activities)
      );
      toast.success(`Atividade atualizada corretamente`);
    },
    onError: (error) =>
      toast.error(
        `Algo deu errado e não foi possível atualizar a atividade: ${error}`
      ),
  });
};
