import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Button } from "components/Buttons";
import { Input } from "components/Inputs";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  DateInputs,
  DoubleItemsWrapper,
  TableContainer,
  InputLabel,
  DateContent,
  AddButtonWrapper,
} from "../../style";
import {
  ActivitiesSection,
  EditButton,
  SectionTitle,
  ErrorAndInputContainer,
} from "./style";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { currencyToNumber, numberToCurrency } from "utils/formatters";
import { queryClient, reactQueryClient } from "api/queryClient";
import { ActivitiesInputForms } from "./types";
import { activitiesCollum } from "./schemas";
import { Props } from "./types";
import Switch from "components/Inputs/Switch";
import { DeleteWrapper } from "pages/Travel/Table/style";
import { DeleteSVG } from "assets/svg/components";
import ConfirmModal from "components/ConfirmModal";
import { Pen } from "assets/svg/components/Pen";

import Section from "components/Section/Section";
import { Stack } from "@mui/material";
import { removeEmptyStringAttributes } from "utils/removeEmptyStringAttributes";
import { useCreateTravelActivity } from "pages/Travel/Register/hooks/queries/activities/useCreateTravelActivity";
import { useSelectedIdContext } from "context/SelectedIdContext";
import { useListTravelActivities } from "pages/Travel/Register/hooks/queries/activities/useListTravelActivities";
import { useDeleteTravelActivity } from "pages/Travel/Register/hooks/queries/activities/useDeleteTravelActivity";
import { travel_activities } from "types/data/TravelActivities";
import DateInputMask from "pages/Travel/Register/components/ActivitiesForm/components/DateInputMask";
import CurrencyInput from "pages/Travel/Register/components/ActivitiesForm/components/CurrencyInput";
import TimeInput from "pages/Travel/Register/components/ActivitiesForm/components/TimeInput";
import { useUpdateTravelActivity } from "pages/Travel/Register/hooks/queries/activities/useUpdateTravelActivity";

const ActivitiesForm = ({ disable }: Props) => {
  const { selectedId: travelId } = useSelectedIdContext();

  const { data: travelActivities, isLoading: isLoadingTravelActivities } =
    useListTravelActivities();

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [showModal, setShowModal] = React.useState(false);
  const [selectedTravelActivity, setSelectedTravelActivity] = useState<any>();
  const [editorValue, setEditorValue] = useState("");
  const [currentTravelActivity, setCurrentTravelActivity] =
    useState<Partial<travel_activities>>();

  const { mutate: createTravelActivity } = useCreateTravelActivity();
  const { mutate: updateTravelActivity } = useUpdateTravelActivity();
  const { mutate: deleteTravelActivity } = useDeleteTravelActivity();

  const { register, setValue, control, handleSubmit, reset } =
    useForm<ActivitiesInputForms>({
      defaultValues: {
        activityTitle: "",
        activityType: "",
        activityDescription: "",
        activityAddress: "",
        activityComment: "",
        startsat: "",
        endsat: "",
        activityOrder: "",
        activityBudget: "",
        activitydate: "",
        activityDay: "",
        activityMonth: "",
        activityYear: "",
      },
    });

  const handleDeleteTravelActivity = () => {
    const [travelActivityId] = selectedTravelActivity;
    const currentActivityToDelete = travelActivities?.find(
      (item) => item.id === travelActivityId
    );

    if (
      currentActivityToDelete?.id &&
      currentActivityToDelete?.activities?.id
    ) {
      deleteTravelActivity({
        travelActivityId: currentActivityToDelete.id,
        activity_id: currentActivityToDelete.activities.id,
      });
      setSelectedTravelActivity([]);
    }
    resetFormData();
    setShowModal(false);
  };

  const onSubmit: SubmitHandler<ActivitiesInputForms> = (
    data: ActivitiesInputForms
  ) => {
    const requestData = removeEmptyStringAttributes({
      ...data,
      activityBudget: currencyToNumber(data.activityBudget),
      activitydate: `${data.activityYear}-${data.activityMonth}-${data.activityDay}`,
      activityDescription: editorValue,
      isvisible: isVisible,
      travel_id: travelId,
      id: currentTravelActivity?.id,
      activity_id: currentTravelActivity?.activity_id,
    });

    if (requestData?.id) {
      updateTravelActivity(requestData, {
        onSuccess: () => {
          reactQueryClient.invalidateQueries({
            queryKey: ["list-travel-activities", travelId],
          });
          queryClient.invalidateQueries({
            queryKey: ["list-travel-activities", travelId],
          });
          resetFormData();
        },
      });
    } else {
      createTravelActivity(requestData, {
        onSuccess: () => {
          reactQueryClient.invalidateQueries({
            queryKey: ["list-travel-activities", travelId],
          });
          queryClient.invalidateQueries({
            queryKey: ["list-travel-activities", travelId],
          });
          resetFormData();
        },
      });
    }
  };

  const resetFormData = () => {
    setCurrentTravelActivity(undefined);
    setEditorValue("");
    setIsVisible(false);
    reset({
      activityBudget: "",
      activityTitle: "",
      activityType: "",
      activityDay: "",
      activityMonth: "",
      activityYear: "",
      startsat: "",
      endsat: "",
      activityOrder: "",
    });
    setSelectedTravelActivity([]);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleSelectionChange = (newSelection: any) => {
    if (newSelection?.length > 1) {
      const selectionSet = new Set(selectedTravelActivity);
      const result = newSelection?.filter((s: any) => !selectionSet.has(s));
      setSelectedTravelActivity(result);
    } else {
      setSelectedTravelActivity(newSelection);
    }
  };

  const handleEditActivity = () => {
    const [selectedTravelActivityId] = selectedTravelActivity;
    const currentActivityToEdit = travelActivities?.find(
      (item) => item.id === selectedTravelActivityId
    );
    if (currentActivityToEdit) {
      setCurrentTravelActivity(currentActivityToEdit);
    }
  };

  useEffect(() => {
    if (currentTravelActivity) {
      setIsVisible(currentTravelActivity?.isvisible || false);
      setEditorValue(currentTravelActivity?.description || "");
      const [year, month, day] = String(currentTravelActivity?.activitydate)
        ?.split("T")[0]
        .split("-");

      reset({
        activityBudget: numberToCurrency(
          currentTravelActivity?.activities?.budget
        ),
        activityTitle: currentTravelActivity?.activities?.title || "",
        activityType: currentTravelActivity?.activities?.activity_type || "",
        activityDay: day || "",
        activityMonth: month || "",
        activityYear: year || "",
        startsat: currentTravelActivity?.activities?.startsat || "",
        endsat: currentTravelActivity?.activities?.endsat || "",
        activityOrder: currentTravelActivity?.ordenation || "",
      });
    }
  }, [currentTravelActivity, reset, setValue]);

  return (
    <ActivitiesSection>
      <SectionTitle>Atividades:</SectionTitle>
      <Section>
        <ErrorAndInputContainer>
          <Controller
            control={control}
            name="activityTitle"
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Título da atividade"
                label="Título da atividade"
                size="500px"
                color="white"
              />
            )}
          />
        </ErrorAndInputContainer>

        <ErrorAndInputContainer style={{ marginRight: "300px" }}>
          <InputLabel>Visibilidade: </InputLabel>
          <Switch
            options={["Invisível", "Visível"]}
            checked={isVisible}
            handleChange={() => setIsVisible(!isVisible)}
          />
        </ErrorAndInputContainer>
      </Section>
      <Section>
        <ErrorAndInputContainer>
          <Controller
            control={control}
            name="activityType"
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Tipo de atividade"
                label="Tipo de atividade"
                size="500px"
                color={disable ? "" : "white"}
                disable={disable}
              />
            )}
          />
        </ErrorAndInputContainer>

        <DateContent>
          <InputLabel>Data da atividade: </InputLabel>
          <DateInputs>
            <Controller
              control={control}
              name="activityDay"
              render={({ field }) => (
                <DateInputMask
                  {...field}
                  valueType="day"
                  register={{ ...register("activityDay") }}
                  name="activityDay"
                  id="activityDay"
                  color="white"
                  inputSize="20px"
                />
              )}
            />

            <Controller
              control={control}
              name="activityMonth"
              render={({ field }) => (
                <DateInputMask
                  {...field}
                  valueType="month"
                  register={{ ...register("activityMonth") }}
                  name="activityMonth"
                  id="activityMonth"
                  color="white"
                  inputSize="20px"
                />
              )}
            />
            <Controller
              control={control}
              name="activityYear"
              render={({ field }) => (
                <DateInputMask
                  {...field}
                  valueType="year"
                  register={{ ...register("activityYear") }}
                  name="activityYear"
                  id="activityYear"
                  color="white"
                  inputSize="50px"
                />
              )}
            />
          </DateInputs>
        </DateContent>
      </Section>
      <Section>
        <ErrorAndInputContainer>
          <Controller
            control={control}
            name="activityBudget"
            render={({ field }) => (
              <CurrencyInput
                {...field}
                label="Custo"
                id="activityBudget"
                placeholder="Custo previsto"
                color={disable ? "" : "white"}
              />
            )}
          />
        </ErrorAndInputContainer>

        <ErrorAndInputContainer>
          <Controller
            control={control}
            name="startsat"
            render={({ field }) => (
              <TimeInput
                {...field}
                label="Horário de início"
                placeholder={""}
                option={"hour"}
                color="white"
              />
            )}
          />
        </ErrorAndInputContainer>
        <ErrorAndInputContainer>
          <Controller
            control={control}
            name="endsat"
            render={({ field }) => (
              <TimeInput {...field} label="Horário de término" color="white" />
            )}
          />
        </ErrorAndInputContainer>
      </Section>
      <Section>
        <ErrorAndInputContainer>
          <Controller
            control={control}
            name="activityOrder"
            render={({ field }) => (
              <Input
                {...field}
                register={register("activityOrder")}
                label="Ordem de exibição"
                color={"white"}
              />
            )}
          />
        </ErrorAndInputContainer>
      </Section>
      <ErrorAndInputContainer>
        <InputLabel>Descrição da atividade: </InputLabel>
        <ReactQuill
          theme="snow"
          onChange={setEditorValue}
          value={editorValue}
        />
      </ErrorAndInputContainer>
      <DoubleItemsWrapper
        style={{
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <AddButtonWrapper onClick={handleSubmit(onSubmit)}>
          <Button>Salvar Atividade</Button>
        </AddButtonWrapper>

        {selectedTravelActivity?.length === 1 && (
          <DoubleItemsWrapper>
            {/* {selectedActivityId && (
            <RatingButton onClick={() => setRatingModal(true)}>
              <Button color="green">Ver Avaliações</Button>
            </RatingButton>
          )} */}

            <EditButton onClick={handleEditActivity}>
              <Pen />
              <span>Editar</span>
            </EditButton>

            <DeleteWrapper
              type="button"
              onClick={() => setShowModal((old) => !old)}
            >
              <DeleteSVG />
              <span>Deletar</span>
            </DeleteWrapper>
          </DoubleItemsWrapper>
        )}
      </DoubleItemsWrapper>
      <TableContainer>
        <DataGrid
          style={{
            paddingTop: 5,
            paddingLeft: 5,
            borderRadius: 10,
            border: "none",
            height: "750px",
            outline: "none",
          }}
          loading={isLoadingTravelActivities}
          rows={travelActivities || []}
          checkboxSelection
          rowsPerPageOptions={[5]}
          columns={activitiesCollum}
          onSelectionModelChange={handleSelectionChange}
          selectionModel={selectedTravelActivity}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          components={{
            Toolbar: GridToolbar,
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Nada para ver aqui
              </Stack>
            ),
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Nada para ver aqui
              </Stack>
            ),
          }}
        />
      </TableContainer>
      <ConfirmModal
        handleCancel={handleCancel}
        handleConfirm={handleDeleteTravelActivity}
        open={showModal}
        message="Deseja mesmo deletar essa atividade"
      />
      {/* {ratingModal && (
        // TODO: isso não está mostrando as avaliações de fato
        <Rating
          modalIsOn={ratingModal}
          activityId={selectedActivityId}
          setCloseModal={setRatingModal}
        />
      )} */}
    </ActivitiesSection>
  );
};

export default ActivitiesForm;
